import React from 'react';

import { Icon, Wrap, WrapItem, WrapProps } from '@chakra-ui/react';
import IconEuropejskiFundusz from 'icons/eu-projects/new/europejski_fundusz.svg';
import IconFunduszeEuropejskie from 'icons/eu-projects/new/fundusze_europejskie.svg';
import IconPolska from 'icons/eu-projects/new/polska.svg';
import IconRzeczpospolita from 'icons/eu-projects/new/rzeczpospolita_polska.svg';

type PolishLogosProps = {
  showPolandLogo?: boolean;
} & WrapProps;

const ICON = {
  W: '200px',
  H: '80px',
};

export const PolishLogos = ({ showPolandLogo = true, ...styles }: PolishLogosProps) => {
  return (
    <Wrap
      spacing="6"
      justify="center"
      mb={{
        base: 9,
        sm: 10,
        lg: 12,
      }}
      {...styles}
    >
      <WrapItem>
        <Icon as={IconFunduszeEuropejskie} alt="European Funds Smart Growth" w={ICON.W} h={ICON.H} />
      </WrapItem>
      <WrapItem>
        <Icon as={IconRzeczpospolita} alt="Rzeczpospolita Polska" w={'300px'} h={ICON.H} />
      </WrapItem>
      {showPolandLogo && (
        <WrapItem>
          <Icon as={IconPolska} alt="Republic of Poland" w={'150px'} h={ICON.H} />
        </WrapItem>
      )}
      <WrapItem>
        <Icon as={IconEuropejskiFundusz} alt="Unia Europejska" w={'300px'} h={ICON.H} />
      </WrapItem>
    </Wrap>
  );
};
